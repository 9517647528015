import * as React from "react";
import "../../style.css";
import "./style.css";
import BoutonProps from "./BoutonProps";

const Bouton = ({
  type,
  onClick,
  children,
  reverse,
  ...rest
}: BoutonProps & React.HTMLAttributes<HTMLOrSVGElement>) => {
  const styleClass = type ? ["btn", `btn-${type}`] : ["btn", "btn-principal"];
  if (reverse) styleClass.push("inversee");

  return (
    <button className={styleClass.join(" ")} onClick={onClick} {...rest}>
      {children}
    </button>
  );
};
export default Bouton;
