import * as React from "react";
import PasswordStrengthMeterProps from "./PasswordStrengthMeterProps";
import "./style.css";
import "../../style.css";
import { useTranslation } from "react-i18next";

const passwordScore = (password: string) => {
  const regexCharacter = new RegExp(
    "(?=(.*[!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?]){2,})"
  );
  const regexMajuscule = new RegExp("(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,})");
  const regexSameCharacter = new RegExp("(.)\\1{2,}");
  if (
    password.length < 10 ||
    regexSameCharacter.test(password) ||
    isConsecutive(password)
  ) {
    return 0;
  } else {
    if (password.length < 15) {
      return 1;
    } else {
      if (!regexMajuscule.test(password)) {
        return 2;
      } else {
        if (!regexCharacter.test(password)) {
          return 3;
        } else {
          return 4;
        }
      }
    }
  }
};

const isConsecutive = (password: string) => {
  const passwordLower = password.toLowerCase();
  for (let i = 0; i < password.length - 2; i++) {
    if (passwordLower.charAt(i) !== "8" && passwordLower.charAt(i) !== "y") {
      if (
        String.fromCharCode(passwordLower.charCodeAt(i) + 1) ===
          passwordLower.charAt(i + 1) &&
        String.fromCharCode(passwordLower.charCodeAt(i) + 2) ===
          passwordLower.charAt(i + 2)
      ) {
        return true;
      }
    }
    if (passwordLower.charAt(i) !== "1" && passwordLower.charAt(i) !== "b") {
      if (
        String.fromCharCode(passwordLower.charCodeAt(i) - 1) ===
          passwordLower.charAt(i + 1) &&
        String.fromCharCode(passwordLower.charCodeAt(i) - 2) ===
          passwordLower.charAt(i + 2)
      ) {
        return true;
      }
    }
  }
  return false;
};

const PasswordStrengthMeter = ({ password }: PasswordStrengthMeterProps) => {
  const { t } = useTranslation(["sqin-ux"]);
  const bars = [];
  let word;
  let classStyle;
  const score = passwordScore(password);
  switch (score) {
    case 0:
      word = t("sqin_ux.password_invalid");
      classStyle = "invalid";
      break;
    case 1:
      word = t("sqin_ux.password_weak");
      classStyle = "weak";
      break;
    case 2:
      word = t("sqin_ux.password_medium");
      classStyle = "medium";
      break;
    case 3:
      word = t("sqin_ux.password_strong");
      classStyle = "strong";
      break;
    case 4:
      word = t("sqin_ux.very_strong");
      classStyle = "strong";
      break;
  }

  for (let i = 0; i < 4; i++) {
    if (password.length > 0 && i < score) {
      bars.push(<span key={i} className={"bar " + classStyle} />);
    } else {
      bars.push(<span key={i} className={"bar"} />);
    }
  }
  return (
    <div className={"bar-container"} aria-live="polite" aria-atomic="true">
      <div className={"bars"}>{bars}</div>
      {password.length > 0 ? (
        <div className={"word"}>
          <span className={"label word-" + classStyle}>{word}</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PasswordStrengthMeter;
