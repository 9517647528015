import { EnTeteTransactionnelProps } from "./EnTeteTransactionnelProps";
import "./style.css";
import "../../style.css";
import imageimportant from "../../icones/important.svg";

const EnTeteTransactionnel = ({
  children,
  afficherBarreJaune,
}: EnTeteTransactionnelProps) => {
  const barre = afficherBarreJaune ? "barreJaune" : "barreBleu";
  const message = "Attention! Vous êtes dans l'environnement d'acceptation";
  return (
    <div className={barre}>
      <div className="conteneurBarre">{children}</div>

      {afficherBarreJaune && (
        <div className="en-tete-de-page-jaune">
          <div>
            <img src={imageimportant} className="pictogramme" />
            {message}
          </div>
        </div>
      )}
    </div>
  );
};
export default EnTeteTransactionnel;
