import "./style.css";
import { ReactComponent as Modifier } from "../../../images/plus.svg";
import { ChampTexte, Bouton } from "../../../../sqin-ux/src";

const precision =
  "Vous recevez les codes de sécurité, les notifications et les communications à cette adresse courriel :";
const labelBouton = "Modifier";
const titreAction = "Coordonnées";
const labelTexte = "Adresse courriel";

const FormulaireDefinitionCourriel = () => {
  return (
    <div className="conteneur">
      <h3 className="titre">{titreAction}</h3>
      <br />
      <div className="divtextbox">
        <ChampTexte
          name={"courriel"}
          label={labelTexte}
          type={"text"}
          format="extra-large"
          precision={[precision]}
          disabled={false}
        />
      </div>
      <div className="bouton-style">
        <Bouton type={"principal"}>
          <Modifier className={"gauche"} />
          {labelBouton}
        </Bouton>
      </div>
    </div>
  );
};

export default FormulaireDefinitionCourriel;
