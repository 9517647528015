import TexteAreaProps from "./TexteAreaProps";
import "./style.css";

const TexteArea = ({
  value,
  format,
  maxLength,
  placeholder,
  readOnly,
  onChange,
}: TexteAreaProps) => {
  const textareaClassName = `textarea ${format || ""}`;
  const textareaContainerClassName = `textarea-container ${format || ""}`;

  const charCount: number = value?.length || 0;

  return (
    <div className={textareaContainerClassName}>
      <textarea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        className={textareaClassName}
        readOnly={readOnly}
      />
      {maxLength && (
        <div className="counter">
          {charCount}/{maxLength}
        </div>
      )}
    </div>
  );
};

export default TexteArea;
