import * as React from "react";
import { ProgressBar } from "ms-react-progress-bar";
import { SpinnerCircularFixed } from "spinners-react";

//import 'ms-react-progress-bar/dist/ProgressBar.css';
import "./style.css";
import "../../style.css";
import SpinnerAttenteProps from "./SpinnerAttenteProps";

import { ReactComponent as FleurBleu } from "../../icones/fleur-de-lys_bleu.svg";
import { ReactComponent as FleurJaune } from "../../icones/fleur-de-lys_jaune.svg";

const SpinnerAttente = (props: SpinnerAttenteProps) => {
  const options = {
    height: "14px",
    borderRadius: "20px",
    labelSize: "12px",
    labelVisibility: false,
    barColor: "#095797",
    containerColor: "#c5cad2",
    maxValue: 100,
  };

  return (
    <div className={"mc-wrapProgressBox"}>
      {props.type === "Normal" && (
        <div>
          <div className={"mc-progressbox-logo"}>
            <FleurBleu />
          </div>
          <div className={"mc-progressbox"}>
            <SpinnerCircularFixed
              size={80}
              thickness={100}
              speed={50}
              color="#095797"
              secondaryColor="#C5CAD2"
            />
          </div>
        </div>
      )}
      {props.type === "Ralentissement" && (
        <div>
          <div className={"mc-progressbox"}>
            <SpinnerCircularFixed
              size={80}
              thickness={100}
              speed={50}
              color="#E0AD03"
              secondaryColor="#C5CAD2"
            />
          </div>
          <div className={"mc-progressbox-logo"}>
            <FleurJaune />
          </div>
        </div>
      )}
      {props.type === "Surcharge" && (
        <ProgressBar value={props.valeurProgression} options={options} />
      )}
    </div>
  );
};
export default SpinnerAttente;
