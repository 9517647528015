import { MenuVerticalProps } from "./menuVerticalProps";
import "./style.css";
import chevronHaut from "../../icones/chevron-haut.svg";
import chevronBas from "../../icones/chevron-bas.svg";
import { Link, useLocation } from "react-router-dom";
import * as React from "react";

const MenuVertical = (
  props: MenuVerticalProps & React.HTMLAttributes<HTMLOrSVGElement>
) => {
  const sortedBoutons = [...props.listeBoutons].sort((btn1, btn2) =>
    btn1.index > btn2.index ? 1 : -1
  );
  const sampleLocation = useLocation();
  const [active, setActive] = React.useState(false);

  return (
    <div className="conteneurPrincipal">
      <a
        href="#"
        className={"icon mobileOnly"}
        onClick={() => setActive(!active)}
      >
        <span>{props.titreMenu}</span>
        <i className={"chevron"}>
          <img src={active ? chevronHaut : chevronBas} alt="chevron" />
        </i>
      </a>

      <span className="titreDesktop desktopOnly">{props.titreMenu}</span>
      <div id="myLinks" className={active ? "active" : ""}>
        {sortedBoutons.map(function (boutonAttr, index) {
          const actif = boutonAttr.route === sampleLocation.pathname;
          return (
            <Link
              key={index}
              to={boutonAttr.route}
              className="texteBouton"
              aria-disabled={actif}
            >
              <div
                key={boutonAttr.id}
                id={boutonAttr.id}
                tabIndex={boutonAttr.index}
                className={actif ? "conteneurEnfant actif" : "conteneurEnfant"}
              >
                <span
                  className={
                    boutonAttr.route === sampleLocation.pathname
                      ? "texte-icone actif"
                      : "texte-icone"
                  }
                >
                  {boutonAttr.text}
                </span>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default MenuVertical;
