import { ReactComponent as Utilisateur } from "../sqin-ux/src/icones/coordonnees.svg";
import { ReactComponent as Cadenas } from "../sqin-ux/src/icones/securite.svg";
import { ReactComponent as Consentement } from "../sqin-ux/src/icones/conditions-consentements.svg";
import { ReactComponent as Statut } from "../sqin-ux/src/icones/conditions-consentements.svg";
import { ReactComponent as Activites } from "../sqin-ux/src/icones/activites-compte.svg";
import { BoutonMenuProps } from "../sqin-ux/src/Composants/MenuVertical/menuVerticalProps";

const listeMenu: BoutonMenuProps[] = [
  {
    index: 1,
    name: "coordonnes",
    text: "Coordonnées",
    id: "div1",
    route: "/menu-vertical/definition-courriel",
  },
  {
    index: 2,
    name: "mdp",
    text: "Sécurité de connexion",
    id: "div2",
    route: "/menu-vertical/modification-mdp",
  },
  {
    index: 3,
    name: "act",
    text: "Activités du compte",
    id: "div3",
    route: "/menu-vertical/gerer-compte",
  },
  {
    index: 4,
    name: "stc",
    text: "Statut du compte",
    id: "div4",
    route: "/menu-vertical/modification-courriel",
  },
  {
    index: 5,
    name: "cdn",
    text: "Conditions et consentements",
    id: "div5",
    route: "/menu-vertical/",
  },
];

export default listeMenu;
