import * as React from "react";
import { Link } from "react-router-dom";
import "./Sidebar.css";

const Sidebar = () => {
  const [sidebar, setSidebar] = React.useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  return (
    <div>
      <button
        className={sidebar ? "hamburger active" : "hamburger"}
        type="button"
        onClick={showSidebar}
      >
        <div />
      </button>
      <nav className={sidebar ? "sidebar active" : "sidebar"}>
        <ul onClick={showSidebar}>
          <li>
            <Link to="/">Accueil</Link>
          </li>
          <li>
            <Link to="/avis">Avis</Link>
          </li>
          <li>
            <Link to="/progress-bar">Barre de progression</Link>
          </li>
          <li>
            <Link to="/bouton-radio">Bouton Radio</Link>
          </li>
          <li>
            <Link to="/boutons">Boutons</Link>
          </li>
          <li>
            <Link to="/carte">Carte Blanche</Link>
          </li>
          <li>
            <Link to="/champs-de-texte">Champs de texte</Link>
          </li>
          <li>
            <Link to="/CaseACocher">Case à cocher</Link>
          </li>
          <li>
            <Link to="/data-table">Data Table</Link>
          </li>
          <li>
            <Link to="/dialogue-modal">Dialogue modal</Link>
          </li>
          <li>
            <Link to="/menu-ellipse">Menu Ellipse</Link>
          </li>
          <li>
            <Link to="/tuile">Tuile</Link>
          </li>
          <li>
            <Link to="/Navigation">Link</Link>
          </li>
          <li>
            <Link to="/Collapsable">Section Pliable</Link>
          </li>
          <li>
            <Link
              to={{
                pathname: "/en-tete-de-page",
                search: "?pleine-largeur=true",
              }}
            >
              En-tête de page
            </Link>
          </li>
          <li>
            <Link
              to={{
                pathname: "/en-tete-transactionnel",
                search: "?pleine-largeur=true",
              }}
            >
              En-tête transactionnel
            </Link>
          </li>
          <li>
            <Link to="/facteur-d-authentification">
              Facteur d'authentification
            </Link>
          </li>
          <li>
            <Link to="/infobulle">Infobulle</Link>
          </li>
          <li>
            <Link to="/liste">Liste</Link>
          </li>
          <li>
            <Link to="/Icons">Liste des icons</Link>
          </li>
          <li>
            <Link to="/menu-vertical">Menu vertical</Link>
          </li>
          <li>
            <Link to="/modal-de-chargement">Modal de chargement</Link>
          </li>
          <li>
            <Link to="/notification">Notification contextuelle</Link>
          </li>
          <li>
            <Link to="/password-strength-meter">Password strength meter</Link>
          </li>
          <li>
            <Link
              to={{ pathname: "/pied-de-page", search: "?pleine-largeur=true" }}
            >
              Pied de page
            </Link>
          </li>
          <li>
            <Link to="/bouton-on-off">Bouton On/Off</Link>
          </li>
          <li>
            <Link to="/bloc-commentaire">Commentaires</Link>
          </li>
          <li>
            <Link to="/texte-area">TexteArea</Link>
          </li>
          <li>
            <Link to="/editeur-texte">Editeur de Texte</Link>
          </li>
          <li>
            <Link to="/liste-deroulante">Liste Deroulante</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
