import { Link } from "react-router-dom";
import "./Style.css";
import NavigationProps from "./NavigationProps";
import { ReactComponent as FlecheGauche } from "../../../src/icones/fleche-gauche.svg";
import * as React from "react";

const Navigation = (props: NavigationProps) => {
  return (
    <div className="paragraphLink">
      <FlecheGauche className="gauche" />
      <Link className="link" to={props.lien}>
        {props.paragraph}
      </Link>
    </div>
  );
};
export default Navigation;
