import { useState } from "react";
import Modal from "react-modal";
import "./Style.css";
import "../../style.css";
import DialogueModalProps from "./DialogueModalProps";

import fermerModal from "../../icones/x-fermer-modal.svg";
import { ReactComponent as FlecheDroite } from "../../icones/fleche-droite.svg";
import { ReactComponent as FlecheGauche } from "../..//icones/fleche-gauche.svg";

import Bouton from "../Bouton/Bouton";
import { useTranslation } from "react-i18next";

const DialogueModal = (props: DialogueModalProps) => {
  const { t } = useTranslation(["sqin-ux"]);
  const [ouvert, definirOuvert] = useState(
    props.modalOuvert ? props.modalOuvert : false
  );

  function basculerModal(e: any) {
    e.preventDefault();
    definirOuvert(!ouvert);
  }

  const closeModal = () => {
    definirOuvert(false);
  };

  const sortedBouton = props.boutonsProps
    ? [...props.boutonsProps].sort((bouton1, bouton2) =>
        bouton1.index > bouton2.index ? 1 : -1
      )
    : [];

  const boutonsDefault =
    props.type === "standard" ? (
      <Bouton type={"principal"} onClick={basculerModal}>
        {t("sqin_ux.dialogue_modale_fermer")}
        {<FlecheDroite className="droite" />}
      </Bouton>
    ) : (
      <>
        <Bouton type={"secondary"} onClick={basculerModal}>
          {<FlecheGauche className="gauche" />}
          {t("sqin_ux.dialogue_modal_annuler")}
        </Bouton>
        <Bouton type={"principal"} onClick={basculerModal}>
          {" "}
          {t("sqin_ux.dialogue_modal_continuer")}
          {<FlecheDroite className="droite" />}
        </Bouton>
      </>
    );

  return (
    <div data-testid={ouvert}>
      <button className={"modal-lien"} onClick={basculerModal}>
        {props.contenuLien}
      </button>

      <Modal
        aria={{ labelledby: "titre" }}
        shouldCloseOnOverlayClick={props.type !== "alerte"}
        isOpen={ouvert}
        onRequestClose={closeModal}
        className={
          props.type === "alerte" ? "dialogueModalAlert" : "dialogueModal"
        }
        overlayClassName={"recouvert"}
        closeTimeoutMS={props.dureeFermetureMS ? props.dureeFermetureMS : 500}
        ariaHideApp={false}
      >
        <div className="enTeteDialogue">
          <h2 id={"titre"}>
            {props.contenuLibelle} <br />
            <div className="ligne_sous_titre" />
          </h2>

          {!props.isSpinner ? (
            <button
              aria-label="Fermer"
              className="imgFermeture"
              onClick={basculerModal}
            >
              <img src={fermerModal} alt={"fermer le modal"} />
            </button>
          ) : (
            ""
          )}
        </div>

        <div className="contenuDialogueModal">{props.children}</div>

        {!props.isSpinner ? (
          <div className={"modal-bouton"}>
            {sortedBouton.length > 0
              ? sortedBouton.map(function (bouton, index) {
                  return (
                    <Bouton
                      key={"btn_" + index}
                      type={bouton.type}
                      onClick={(e) => {
                        if (bouton.onClick) {
                          bouton.onClick(e);
                        }
                        if (!bouton.fermetureManuelleModal) {
                          basculerModal(e);
                        }
                      }}
                    >
                      {bouton.children}
                    </Bouton>
                  );
                })
              : boutonsDefault}
          </div>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};
export default DialogueModal;
