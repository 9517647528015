import * as React from "react";
import './style.css'
import '../../style.css';
import FacteurDAuthentificationProps from "./FacteurDAuthentificationProps";

const contenu = (type: string, titre: string, content: string) => {
    const classEmail = ['facteur-email'];
    if(type !== 'Code'){
        classEmail.push('wrap-email')
    }
    if (type === 'Code')
        return <div className={'facteur-contenu'}>
                    <div className={'facteur-texte-courant'}>{titre}</div>
                    <div className={'facteur-code'}> {content}</div>
                </div>

  return (
    <div className={"facteur-contenu"}>
      <div className={"facteur-texte-courant"}>
        {type === "Recuperation"
          ? "Un mot de passe temporaire a été envoyé à l'adresse :"
          : "Un code sera envoyé à l'adresse :"}
      </div>
      <h3 className={classEmail.join(" ")}>
        {type === "Inscription" ? content : abregerEmail(content)}
      </h3>
    </div>
  );
};

const FacteurDAuthentification = ({type, titre, content}: FacteurDAuthentificationProps) => {


    let formatClass = '';

    switch (type) {
        case "Code":
            formatClass = 'facteur-sm';
            break;
        case "Connexion":
            formatClass = 'facteur-md';
            break;
        case "Inscription":
            formatClass = 'facteur-lg';
            break;
        case "Recuperation":
            formatClass = 'facteur-xlg';
            break;
    }

    return (<div className={'facteur-conteneur ' + formatClass}>
        <div className={'facteur-texte-conteneur'}>
            {contenu(type, titre, content)}
        </div>
    </div>)
}

const abregerEmail = (email: string) => {
    if (email.indexOf('@') > 4) {
        return email.replace(email.slice(2, email.indexOf('@') - 2), '....')
    } else {
        return email.replace(email.slice(1, email.indexOf('@') - 1), '....')
    }
}

export default FacteurDAuthentification;
