import * as React from "react";
import "../../style.css";
import "./style.css";
import { useState } from "react";
import { BoutonRadioProps } from "./BoutonRadioProps";
import ChampTexte from "../ChampTexte/ChampTexte";
import DialogueModal from "../DialogueModal/DialogueModal";

const BoutonRadio = (props: BoutonRadioProps) => {
  const [etat, setetat] = useState(
    props.selected !== undefined ? props.selected : -1
  );

  const radioHandler = (nouvelEtat: React.SetStateAction<number>) => {
    setetat(nouvelEtat);

    if (props.onChange) {
      const selectedValue = sortedRadios.find(
        (radio) => radio.index == nouvelEtat
      )?.value;
      if (selectedValue !== undefined) props.onChange(selectedValue);
    }
  };

  const sortedRadios = [...props.listeChampsRadio].sort((radio1, radio2) =>
    radio1.index > radio2.index ? 1 : -1
  );

  return (
    <fieldset role="radiogroup" key={"radiogroup"}>
      <legend className="conteneur_titre">
        <p className="texte-titre">{props.titre}</p>

        {props.afficherAide && (
          <div className="conteneur_aide">
            <DialogueModal {...props.dialogueModalPrincipalProps}>
              {props.dialogModalPrincipalContent}
            </DialogueModal>
          </div>
        )}
      </legend>

      {sortedRadios.map(function (champsRadioAttr, index) {
        const libelleRadioClassName = `libelleRadio ${
          champsRadioAttr.type || ""
        }`;
        return (
          <div id={"conteneur" + index} key={"conteneur_" + index}>
            <div
              key={"div1_" + index}
              id={"div1_" + champsRadioAttr.index}
              className="conteneur_principal"
            >
              <div
                key={"divInput1_" + index}
                id={"divInput1_" + champsRadioAttr.index}
                className="div_boutonRadio"
              >
                <input
                  data-testid={"bouton-radio-test" + champsRadioAttr.index}
                  key={"radio_" + index}
                  type="radio"
                  id={champsRadioAttr.id}
                  checked={etat === champsRadioAttr.index}
                  name={champsRadioAttr.name}
                  disabled={champsRadioAttr.disabled}
                  value={champsRadioAttr.value}
                  onChange={(_e) => radioHandler(champsRadioAttr.index)}
                />
              </div>
              <div
                className="conteneur-champTexte"
                key={"conteneur-champTexte_" + index}
              >
                <div
                  key={"divLabel1_" + index}
                  id={"divLabel1_" + champsRadioAttr.index}
                  className="conteneur_dialogue"
                >
                  <label
                    key={"lbl1_" + index}
                    id={"lbl1_" + champsRadioAttr.index}
                    className={libelleRadioClassName}
                    htmlFor={champsRadioAttr.id}
                    aria-disabled={champsRadioAttr.disabled}
                  >
                    {champsRadioAttr.libelle}
                  </label>

                  {champsRadioAttr.afficherAide && (
                    <div
                      key={"divAide1_" + index}
                      id={"divAide1_" + champsRadioAttr.index}
                      className="conteneur_aide"
                    >
                      <DialogueModal {...champsRadioAttr.dialogueModalProps}>
                        {champsRadioAttr.dialogModalContent}
                      </DialogueModal>
                    </div>
                  )}
                </div>
                {etat === champsRadioAttr.index &&
                  props.type === "champsTexte" &&
                  champsRadioAttr.champTexteProps !== null && (
                    <div
                      key={"divTextBox1_" + index}
                      id={"divTextBox1_" + champsRadioAttr.index}
                      className="conteneur_champs"
                    >
                      <div
                        className="texte_precision"
                        id={"prec1_" + champsRadioAttr.index}
                        key={"prec1_" + champsRadioAttr.index}
                      >
                        {champsRadioAttr.texteChamps}
                      </div>

                      <ChampTexte
                        key={"txt_" + champsRadioAttr.index}
                        {...champsRadioAttr.champTexteProps}
                        ariaLabelled={"lbl1_" + champsRadioAttr.index}
                        ariaDescribed={"prec1_" + champsRadioAttr.index}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        );
      })}
    </fieldset>
  );
};

export default BoutonRadio;
