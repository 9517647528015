import React, { useState } from "react";
import { Column } from "../sqin-ux/src";
import DataTable from "../sqin-ux/src/Composants/DataTable/DataTable";
import { OptionMenuProps } from "../sqin-ux/src/Composants/MenuEllipse/MenuEllipseProps";

type Data = {
  id: number;
  name: string;
  date: string;
  detailCollapse?:   React.ReactNode;
};

const columns: Column<Data>[] = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Nom",
  },
  {
    id: "date",
    label: "Date",
    getLabel: (label) => {
      return new Date(label).toLocaleString("fr-CA", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
  },
  {
    id: "detailCollapse",
    label: "Detail",
  }
];

type valueType = Data[keyof Data];

const ExempleDataTable = () => {
  const [selection, setSelection] = useState<number[]>([]);
  const data: Data[] = [
    {
      id: 1,
      name: "John Nicola",
      date: "2023-05-12",
      detailCollapse: <div>
      <label><b>Description</b></label>
      <br/>
      <label>Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</label>
      <br/><br/>
      <label><b>Nature d’exécution</b></label>
      <br/>
      <label>Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum ipsum Lorem ipsum Lorem ipsum</label>
      <br/><br/>
      <label><b>Détail du traitement</b></label>
      <br/>
      <label>Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</label>
      </div>,
    },
    {
      id: 2,
      name: "John Daqlo",
      date: "2023-05-12",
      detailCollapse: <p>detatil 2 <br/> Lorem ipsum Lorem ipsum</p>,
    },
    {
      id: 3,
      name: "Robio Daqlo",
      date: "2023-05-12"
    },
  ];
  function actionClick(
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLLIElement>,
    data?: Data
  ) {
    console.log(data);
  }
  const boutonMenuEllipse: OptionMenuProps<Data>[] = [
    { key: "option1", selected: false, onClick: actionClick },
    { key: "option2", selected: false, onClick: actionClick },
    { key: "option3", selected: false, onClick: actionClick },
    { key: "option4", selected: false, onClick: actionClick },
  ];

  const handleChangeBtnClick = (data: Data) => {
    const index = selection.indexOf(data.id);
    const selectionEdit = [...selection];
    if (index < 0) {
      selectionEdit.push(data.id);
    } else {
      selectionEdit.splice(index, 1);
    }
    setSelection([...selectionEdit]);
  };

  const isSelected = (id: valueType) => {
    return id?selection.includes(+id): false;
  };

  return (
    <div style={{ margin: "auto" }}>
      <DataTable
        data={data}
        columns={columns}
        page={0}
        rowsPerPage={10}
        count={3}
        handleClick={(event, name) => console.log(name)}
        handleChangePage={(event, newPage) => console.log(newPage)}
        handleChangeRowsPerPage={(event) => console.log(event)}
        defaultSort={"id"}
        label={"Personne"}
      />
      <br />
      <br />
      <DataTable
        data={data}
        columns={columns}
        page={0}
        rowsPerPage={10}
        count={3}
        handleClick={(event, name) => console.log(name)}
        handleChangePage={(event, newPage) => console.log(newPage)}
        handleChangeRowsPerPage={(event) => console.log(event)}
        defaultSort={"id"}
        label={"Personne"}
        onOffActivated
        menuEllipseActivated
        boutonMenuEllipse={boutonMenuEllipse}
        onChangeSwitch={handleChangeBtnClick}
        isSelected={isSelected}
        selectBy={"id"}
        collapse
      />
    </div>
  );
};

export default ExempleDataTable;
