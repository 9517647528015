import React, { useState, useEffect } from "react";

import InfobulleProps from "./InfobulleProps";
import { Popup } from "semantic-ui-react";
import "./style.css";
import "../../style.css";

import fermerInfobulle from "../../icones/x-fermer-modal.svg";
import aideInfobulle from "../../icones/infobulle-aide-2.svg";

const Infobulle = (props: InfobulleProps) => {
  const [ouvert, definirOuvert] = useState(false);
  const [isDesktop, setDesktop] = useState(false);

  const contextRef = React.useRef<any | null>();

  function setWidth() {
    const popupMobile = document.getElementById("popup_mobile") as HTMLElement;
    const largeurPage = popupMobile?.parentElement?.parentElement?.clientWidth;
    if (popupMobile && largeurPage) {
      popupMobile.style.width = largeurPage + "px";
    }
  }

  useEffect(() => {
    if (!isDesktop && ouvert) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [ouvert, isDesktop]);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }

    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const updateMedia = () => {
    if (window.innerWidth > 590) {
      setDesktop(true);
    } else {
      setDesktop(false);
      setWidth();
    }
  };

  return (
    <div className="conteneur-aide">
      {isDesktop ? (
        <Popup
          on="click"
          open={ouvert}
          onOpen={() => definirOuvert(true)}
          onClose={() => definirOuvert(false)}
          pinned
          position={props.position ?? "right center"}
          className={"popup_desktop"}
          trigger={
            <button className="info-lien">
              <img src={aideInfobulle} alt="" />
            </button>
          }
        >
          <button
            aria-label="Fermer"
            className="imgFermetureTT"
            onClick={() => definirOuvert(false)}
          >
            <img src={fermerInfobulle} alt={"fermer le modal"} />
          </button>
          <div className="inside_tooltip">
            <strong>{props.titre}</strong>
            <br />
            {props.message}
          </div>
        </Popup>
      ) : (
        <>
          <Popup
            on="click"
            open={ouvert}
            onOpen={() => definirOuvert(true)}
            onClose={() => definirOuvert(false)}
            context={contextRef}
            size={"huge"}
            wide={"very"}
            id={"popup_mobile"}
            className={"popup_mobile"}
            trigger={
              <button className="info-lien">
                <img src={aideInfobulle} alt="" />
              </button>
            }
          >
            <button
              aria-label="Fermer"
              className="imgFermetureTT"
              onClick={() => definirOuvert(false)}
            >
              <img src={fermerInfobulle} alt={"fermer le modal"} />
            </button>
            <div className="inside_tooltip_mobile_conteneur">
              <strong className="inside_tooltip_mobile">{props.titre}</strong>
              <br />
              <div className="inside_tooltip_mobile">{props.message}</div>
            </div>
          </Popup>
          <div ref={contextRef} className={"infobulle_mobile"} />
        </>
      )}
    </div>
  );
};

export default Infobulle;
