import { useState } from "react";
import Select from "react-select";

import activitescompte from "../../icones/activites-compte.svg";
import aide from "../../icones/aide.svg";
import appareil from "../../icones/appareil.svg";
import calendrier_gestion_compte from "../../icones/calendrier-gestion-compte.svg";
import calendrier from "../../icones/calendrier.svg";
import chevron_bas from "../../icones/chevron-bas.svg";
import chevron_haut from "../../icones/chevron-haut.svg";
import cle_activation from "../../icones/cle-activation.svg";
import close from "../../icones/close.png";
import conditions_consentements from "../../icones/conditions-consentements.svg";
import coordonnees from "../../icones/coordonnees.svg";
import courriel from "../../icones/courriel.svg";
import crochet_et_cercle_connexion from "../../icones/crochet-et-cercle-connexion.svg";
import crochet_et_cercle_statut from "../../icones/crochet-et-cercle-statut.svg";
import crochet_etape from "../../icones/crochet-etape.svg";
import crochet from "../../icones/crochet.svg";
import droit from "../../icones/droit.svg";
import empreintes from "../../icones/empreintes.svg";
import erreur from "../../icones/erreur.svg";
import fleche_droite from "../../icones/fleche-droite.svg";
import fleche_gauche from "../../icones/fleche-gauche.svg";
import fleur_de_lys_bleu from "../../icones/fleur-de-lys_bleu.svg";
import fleur_de_lys_jaune from "../../icones/fleur-de-lys_jaune.svg";
import gestion_des_alertes from "../../icones/gestion-des-alertes.svg";
import gestion_des_traitements from "../../icones/gestion-des-traitements.svg";
import horloge from "../../icones/horloge.svg";
import ico_loupe from "../../icones/ico_loupe.svg";
import Icone_SAG_securite from "../../icones/Icone_SAG_securite.svg";
import important_modal from "../../icones/important_modal.svg";
import important from "../../icones/important.svg";
import infobulle_aide_2 from "../../icones/infobulle-aide-2.svg";
import information from "../../icones/information.svg";
import inscription from "../../icones/inscription.svg";
import introduction_acces from "../../icones/introduction-acces.svg";
import introduction_authentification from "../../icones/introduction-authentification.svg";
import introduction_securite from "../../icones/introduction-securite.svg";
import lien_externe_couleur from "../../icones/lien-externe-couleur.svg";
import lien_externe_white from "../../icones/lien-externe-white.svg";
import limitations from "../../icones/limitations.svg";
import liste_des_traitements from "../../icones/liste-des-traitements.svg";
import localisation from "../../icones/localisation.svg";
import moins from "../../icones/moins.svg";
import ne_pas_voir from "../../icones/ne-pas-voir.svg";
import se_connecter from "../../icones/se-connecter.svg";
import securite from "../../icones/securite.svg";
import succes from "../../icones/succes.svg";
import supprimer_compte from "../../icones/supprimer-compte.svg";
import telephone_gestion_compte from "../../icones/telephone-gestion-compte.svg";
import telephone from "../../icones/telephone.svg";
import vie_privee from "../../icones/vie-privee.svg";
import voir from "../../icones/voir.svg";
import vol_identite from "../../icones/vol-identite.svg";
import x_fermer_modal from "../../icones/x-fermer-modal.svg";
import x_fermer from "../../icones/x-fermer.svg";
import x_non from "../../icones/x-non.svg";
import enlever from "../../icones/enlever.svg";
import ajouter from "../../icones/ajouter.svg";

import "./style.css";

type Icon = { icon: string; name: string };
type Option = { label: string; value: string };

const options = [
  { label: "blanc", value: "icon-box blanc" },
  { label: "noire", value: "icon-box noire" },
  { label: "bleue", value: "icon-box bleue" },
];

const icons = [
  { icon: succes, name: "succes" },
  { icon: erreur, name: "erreur" },
  { icon: important, name: "important" },
  { icon: x_non, name: "x-non.svg" },
  { icon: information, name: "information" },
  { icon: activitescompte, name: "activites-compte.svg" },
  { icon: aide, name: "aide.svg" },
  { icon: appareil, name: "appareil.svg" },
  { icon: calendrier_gestion_compte, name: "calendrier-gestion-compte.svg" },
  { icon: calendrier, name: "calendrier.svg" },
  { icon: chevron_bas, name: "chevron-bas.svg" },
  { icon: chevron_haut, name: "chevron-haut.svg" },
  { icon: cle_activation, name: "cle-activation.svg" },
  { icon: close, name: "close.png" },
  { icon: conditions_consentements, name: "conditions-consentements.svg" },
  { icon: coordonnees, name: "coordonnees.svg" },
  { icon: courriel, name: "courriel.svg" },
  {
    icon: crochet_et_cercle_connexion,
    name: "crochet-et-cercle-connexion.svg",
  },
  { icon: crochet_et_cercle_statut, name: "crochet-et-cercle-statut.svg" },
  { icon: crochet_etape, name: "crochet-etape.svg" },
  { icon: crochet, name: "crochet.svg" },
  { icon: droit, name: "droit.svg" },
  { icon: empreintes, name: "empreintes.svg" },
  { icon: erreur, name: "erreur.svg" },
  { icon: fleche_droite, name: "fleche-droite.svg" },
  { icon: fleche_gauche, name: "fleche-gauche.svg" },
  { icon: fleur_de_lys_bleu, name: "fleur-de-lys_bleu.svg" },
  { icon: fleur_de_lys_jaune, name: "fleur-de-lys_jaune.svg" },
  { icon: gestion_des_alertes, name: "gestion-des-alertes.svg" },
  { icon: gestion_des_traitements, name: "gestion-des-traitements.svg" },
  { icon: horloge, name: "horloge.svg" },
  { icon: ico_loupe, name: "ico-loupe.svg" },
  { icon: Icone_SAG_securite, name: "Icone_SAG_securite.svg" },
  { icon: important_modal, name: "important-modal.svg" },
  { icon: infobulle_aide_2, name: "infobulle-aide-2.svg" },
  { icon: information, name: "information.svg" },
  { icon: inscription, name: "inscription.svg" },
  { icon: introduction_acces, name: "introduction-acces.svg" },
  { icon: information, name: "information.svg" },
  {
    icon: introduction_authentification,
    name: "introduction-authentification.svg",
  },
  { icon: introduction_securite, name: "introduction_securite" },
  { icon: lien_externe_couleur, name: "lien_externe_couleur" },
  { icon: lien_externe_white, name: "lien_externe_white" },
  { icon: limitations, name: "limitations.svg" },
  { icon: liste_des_traitements, name: "liste-des-traitements.svg" },
  { icon: localisation, name: "localisation.svg" },
  { icon: moins, name: "moins.svg" },
  { icon: ne_pas_voir, name: "ne-pas-voir.svg" },
  { icon: se_connecter, name: "se-connecter.svg" },
  { icon: securite, name: "securite.svg" },
  { icon: supprimer_compte, name: "supprimer-compte.svg" },
  { icon: telephone_gestion_compte, name: "telephone-gestion-compte.svg" },
  { icon: telephone, name: "telephone.svg" },
  { icon: vie_privee, name: "vie-privee.svg" },
  { icon: voir, name: "voir.svg" },
  { icon: vol_identite, name: "vol-identite.svg" },
  { icon: x_fermer_modal, name: "x-fermer-modal.svg" },
  { icon: x_fermer, name: "x-fermer.svg" },
  { icon: enlever, name: "enlever.svg" },
  { icon: ajouter, name: "ajouter.svg" },
] as Icon[];

const IconShow = ({ imageIcon, theme }: { imageIcon: Icon; theme: string }) => (
  <div className={theme}>
    <img src={imageIcon.icon} alt={imageIcon.name} />
    <div>{imageIcon.name}</div>
  </div>
);

export function IconList() {
  const [style, setStyle] = useState<string>(options[0].value);
  return (
    <div>
      <div>
        Theme: <Select options={options} onChange={(v) => setStyle(v!.value)} />
      </div>
      <div className="main-wrapper">
        {icons.map((item: Icon) => (
          <IconShow imageIcon={item} key={item.name} theme={style} />
        ))}
      </div>
    </div>
  );
}
