import * as React from "react";
import ListeProps from "./ListeProps";
import "./style.css";
import "../../style.css";

const Liste = ({ listElements, description, customStyle, markerStyle }: ListeProps) => {
  const styleCLass = ["list-container"];
  if (!customStyle) {
    styleCLass.push("default");
  }
  return (
    <div className={styleCLass.join(" ")}>
      {description ? <p>{description}</p> : ""}
      <ul className={"list " + markerStyle}>
        {listElements.map((e, index) => (
          <li key={index} className={"content"}>
            {e.element}
            {e.subElements ? (
              <ul className={"list " +e.markerStyle}>
                {e.subElements.map((sub, i) => (
                  <li key={index + "," + i} className={"content"} >
                    {sub}
                  </li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Liste;
