import DOMPurify from "dompurify";
import CommentaireProps from "./CommentaireProps";
import parseHtml from "html-react-parser";
import "./style.css";

const Commentaire = ({
  commentateur,
  datePublication,
  texte,
}: CommentaireProps) => {
  return (
    <div className="bloc-commentaire">
      <p className="commentaire">
        <b>{commentateur}</b> a publié un commentaire le {datePublication}{" "}
      </p>
      <p className="commentaire">{parseHtml(DOMPurify.sanitize(texte))}</p>
    </div>
  );
};

export default Commentaire;
