import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import Sidebar from "./sqin-ux/src/Composants/Sidebar/Sidebar";

function App() {
  const applicationEnModeIFrame = window.location !== window.parent.location;
  return (
    <Router>
      {applicationEnModeIFrame ? "" : <Sidebar />}
      <Routes />
    </Router>
  );
}

export default App;
