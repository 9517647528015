import { useNavigate } from "react-router-dom";
import "./style.css";
import TuileProps from "./TuileProps";
import * as React from "react";
const Tuile = (props: TuileProps) => {
  const navigate = useNavigate();
  return (
    <div className="tuile" onClick={() => navigate(props.lien)}>
      <div className="tuile-header">
        <img src={props.icon} />
        <h2>{props.title}</h2>
      </div>
      <p>{props.paragraph}</p>
    </div>
  );
};
export default Tuile;
