import IAvisProps from "./AvisProps";
import imagesucces from "../../icones/succes.svg";
import imageerreur from "../../icones/erreur.svg";
import imageimportant from "../../icones/important.svg";
import imageInformation from "../../icones/information.svg";
import "../../style.css";
import "./style.css";

const Avis = (props: IAvisProps) => {
  const typeclass = "avis-zonecoloree-" + props.type;
  let pictoavis: string;
  let altervativeImage: string;

  if (props.type === "erreur") {
    pictoavis = imageerreur;
    altervativeImage = "erreur";
  } else if (props.type === "important") {
    pictoavis = imageimportant;
    altervativeImage = "important";
  } else if (props.type === "information") {
    pictoavis = imageInformation;
    altervativeImage = "information";
  } else {
    pictoavis = imagesucces;
    altervativeImage = "succès";
  }

  return (
    <div className="avis-format">
      <div className={typeclass}>
        <img
          src={pictoavis}
          className="avis-pictogramme"
          alt={altervativeImage}
        />
      </div>
      <div className="contenuAvis">{props.children}</div>
    </div>
  );
};
export default Avis;
