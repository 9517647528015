import "./style.css";

import IGroupeCaseACocherProps from "./GroupeCaseACocherProps";

export function GroupeCaseACocher(props: IGroupeCaseACocherProps) {
  /*Construction du component */
  return (
    <div>
      <label className="description" id="description">
        Zone de choix multiples
      </label>
      <fieldset
        className="ChampsGroupeCaseACocher"
        aria-describedby="description"
      >
        <legend className="LegendeGroupeCaseACocher">{props.message}</legend>
        {props.children}
      </fieldset>
    </div>
  );
}
