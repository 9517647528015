import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  styled,
  TableSortLabel,
  Collapse,
  IconButton,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import DataTableProps, { GenericType } from "./DataTableProps";
import { keys } from "lodash";
import BoutonOnOff from "../BoutonOnOff/BoutonOnOff";
import MenuEllipse from "../MenuEllipse/MenuEllipse";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<T>(
  order: Order,
  orderBy: keyof T
): (a: T, b: T) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const DataTable = <T extends GenericType>({
  data,
  columns,
  selectedCount,
  page,
  rowsPerPage,
  count,
  selectBy,
  onSelectAllClick,
  isSelected,
  handleClick,
  handleChangePage,
  handleChangeRowsPerPage,
  defaultSort,
  activeSelect,
  label,
  onOffActivated,
  menuEllipseActivated,
  boutonMenuEllipse,
  onChangeSwitch,
  collapse,
  backEndPagination,
}: DataTableProps<T>) => {
  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#F0F8FF",
    },
    "&.Mui-selected": {
      backgroundColor: "#B1E1FF !important",
    },
  }));

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof T>(defaultSort);
  const [ligneAouvrir, setLigneAouvrir] = React.useState(-1);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler =
    (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };
  const sortedData = backEndPagination
    ? stableSort<T>(data, getComparator(order, orderBy))
    : stableSort<T>(data, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ height: 480 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {onOffActivated && onChangeSwitch ? (
                <TableCell padding="checkbox">État</TableCell>
              ) : activeSelect ? (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selectedCount! > 0 && selectedCount! < count}
                    checked={count > 0 && selectedCount === count}
                    onChange={onSelectAllClick}
                    inputProps={{
                      "aria-label": "selectionner toutes les publications",
                    }}
                  />
                </TableCell>
              ) : null}
              {columns.map((column) =>
                column.id !== "detailCollapse" ? (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  ""
                )
              )}
              {collapse ? (
                <TableCell padding="checkbox" key="collapse">
                  {" "}
                </TableCell>
              ) : null}
              {menuEllipseActivated && boutonMenuEllipse ? (
                <TableCell key="menu" padding="checkbox">
                  Option
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => {
              const selectedBy = selectBy ? selectBy : keys(row)[0];
              const isItemSelected = isSelected
                ? isSelected(row[selectedBy])
                : false;
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <React.Fragment key={row[selectedBy]}>
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    onClick={(event: React.MouseEvent<unknown, MouseEvent>) =>
                      handleClick(event, row[selectedBy])
                    }
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    {onOffActivated && onChangeSwitch ? (
                      <TableCell padding="checkbox">
                        <BoutonOnOff
                          checked={
                            isSelected ? isSelected(row[selectedBy]) : false
                          }
                          onChange={(event) => onChangeSwitch(row)}
                        />
                      </TableCell>
                    ) : activeSelect ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                    ) : null}
                    {columns.map((column, indice) => {
                      const value = row[column.id as number | string];
                      return (
                        <TableCell
                          key={`${column.id} ${row[selectedBy]}`}
                          align={column.align}
                        >
                          {column.id !== "detailCollapse"
                            ? column.getLabel
                              ? column.getLabel(value)
                              : value
                            : ""}
                          {columns.length - 1 == indice &&
                          collapse &&
                          row["detailCollapse"] ? (
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() =>
                                setLigneAouvrir(
                                  ligneAouvrir === index ? -1 : index
                                )
                              }
                            >
                              {ligneAouvrir === index ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      );
                    })}
                    {menuEllipseActivated && boutonMenuEllipse ? (
                      <TableCell padding="checkbox">
                        <MenuEllipse
                          data={row}
                          listeOptions={boutonMenuEllipse}
                        />
                      </TableCell>
                    ) : null}
                  </StyledTableRow>
                  {collapse ? (
                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          borderStyle: "hidden",
                        }}
                        colSpan={columns.length + 3}
                      >
                        <Collapse
                          in={ligneAouvrir === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ margin: 1 }}>{row["detailCollapse"]}</Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={label + " par page"}
        labelDisplayedRows={({ from, to, count }) =>
          "" + from + "-" + to + " de " + count
        }
      />
    </Paper>
  );
};
export default DataTable;
