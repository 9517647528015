import EditeurTexteProps from "./EditeurTexteProps";
import "./style.css";
import Bouton from "../Bouton/Bouton";
import { useMemo, useRef } from "react";
import JoditEditor from "jodit-react";
import { InsertMode } from "jodit/esm/types";

const EditeurTexte = ({
  htmlTexte,
  placeholder,
  buttons,
  onChange,
}: EditeurTexteProps) => {
  const mode: InsertMode = "insert_only_text";
  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder ?? "",
      minHeight: 10,
      maxHeight: 460,
      language: "fr",
      askBeforePasteHTML: false,
      defaultActionOnPaste: mode,
      buttons:
        "bold,italic,underline,strikethrough,|,ul,ol,indent,outdent,|,link,|,brush,|,eraser",
      disablePlugins:
        "powered-by-jodit,about,print,preview,fullsize,spellcheck,search,file,image,video,image-processor,image-properties,iframe,clipboard,font,media,inline-popup,paste-from-word,paste-storage,paste,symbols,mobile,dtd,source,speech-recognize,stat,xpath,class-span,justify,format-block,copyformat,add-new-line,line-height,key-arrow-outside,tab,table-keyboard-navigation,table,hr,redo-undo,delete-command,drag-and-drop,drag-and-drop-element,inline-popup,sticky,",
    }),
    [placeholder]
  );

  return (
    <div className="editor-container">
      <JoditEditor
        ref={editor}
        value={htmlTexte ?? ""}
        config={config}
        onBlur={onChange}
      />
      {buttons && buttons?.length > 0 && (
        <div className="button-container">
          {buttons.map(function (bouton, index) {
            return (
              <Bouton
                key={"btn_" + index}
                type={bouton.type}
                onClick={bouton.onClick}
              >
                {bouton.children}
              </Bouton>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default EditeurTexte;
