import LogoQuebecCouleur from "../../logos/logo_Quebec_couleur.svg";
import iconeCalendrier from "../../icones/calendrier.svg";
import iconeHorloge from "../../icones/horloge.svg";
import iconeTelephone from "../../icones/telephone.svg";
import { PiedDePageProps } from "./PiedDePageProps";

const piedDePageParDefaut: PiedDePageProps = {
  premiereSectionColonne1: [
    {
      titre: {
        libelle: "sqin_ux.header_title",
      },
      liens: [
        {
          libelle: "sqin_ux.footer_sinformer",
          afficherIconeLienExterne: true,
          url: "https://www.quebec.ca/gouvernement/faire-affaire-gouvernement/authentification-gouvernementale",
        },
        {
          libelle: "sqin_ux.footer_prevention_fraude",
          afficherIconeLienExterne: true,
          url: "https://contenu.authentification.quebec.ca/prevention-fraude/prevention-fraude.html",
        },

        {
          libelle: "sqin_ux.footer_consentement",
          afficherIconeLienExterne: true,
          url: "https://contenu.authentification.quebec.ca/consentement/consentement.html",
        },
        {
          libelle: "sqin_ux.footer_plaintes",
          afficherIconeLienExterne: true,
          url: "https://www.quebec.ca/nous-joindre/formuler-une-plainte",
        },
      ],
    },
  ],

  premiereSectionColonne2: [
    {
      titre: {
        libelle: "sqin_ux.footer_nous_rejoindre",
        url: "#/",
      },
      iconeEtLibelle: [
        {
          icone: iconeCalendrier,
          libelle: ["sqin_ux.footer_lundi_vendredi"],
        },
        {
          icone: iconeHorloge,
          libelle: ["sqin_ux.footer_horaire"],
        },
        {
          icone: iconeTelephone,
          libelle: ["sqin_ux.footer_num_tel1", "sqin_ux.footer_num_tel2"],
        },
      ],
    },
  ],
  liensDeuxiemeSection: [
    {
      libelle: "sqin_ux.footer_accessibilite",
      afficherIconeLienExterne: true,
      url: "https://contenu.authentification.quebec.ca/accessibilite/accessibilite.html",
    },
    {
      libelle: "sqin_ux.footer_politique_conf",
      afficherIconeLienExterne: true,
      url: "https://contenu.authentification.quebec.ca/politique-confidentialite/politique-confidentialite.html",
    },
    {
      libelle: "sqin_ux.footer_condition",
      afficherIconeLienExterne: true,
      url: "https://contenu.authentification.quebec.ca/conditions/conditions.html",
    },
    {
      libelle: "sqin_ux.footer_exceptions",
      afficherIconeLienExterne: true,
      url: "https://www.quebec.ca/gouvernement/politiques-orientations/langue-francaise/modernisation-charte-langue-francaise",
    },
  ],
  logo: {
    logo: LogoQuebecCouleur,
    alt: "sqin_ux.footer_lien_gouv",
    url: "https://www.quebec.ca/",
  },
  copyright: {
    libelle: "sqin_ux.footer_lien_gouv",
    url: "https://www.quebec.ca/droit-auteur",
  },
};

export default piedDePageParDefaut;
