import { Routes, Route } from "react-router-dom";
import { MenuVertical } from "../sqin-ux/src";
import Carte from "../sqin-ux/src/Composants/Carte/Carte";
import ChangePasswordForm from "./gestion-de-compte/change-password-formulaire/ChangePasswordForm";
import Courriel from "./gestion-de-compte/courriel-formulaire/definition-courriel/Courriel";
import FormulaireDefinitionCourriel from "./gestion-de-compte/courriel-formulaire/definition-courriel/DefinitionCourriel";
import GestionDeCompte from "./gestion-de-compte/GestionDeCompte";
import listeMenu from "./listeMenus";
import "./style.css";

const ExempleMenuVertical = () => {
  return (
    <div className={"conteneur-principale"}>
      <Carte>
        <MenuVertical listeBoutons={listeMenu} titreMenu={"Gestion de compte"} />
        <Routes>
          <Route path="/gerer-compte" element={<GestionDeCompte />} />
          <Route
            path="/definition-courriel"
            element={<FormulaireDefinitionCourriel />}
          />
          <Route path="/modification-courriel" element={<Courriel />} />
          <Route path="/modification-mdp" element={<ChangePasswordForm />} />
          <Route path="/" element={<div></div>} />
        </Routes>
      </Carte>
    </div>
  );
};

export default ExempleMenuVertical;
