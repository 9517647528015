import i18next, { i18n } from "i18next";
import EN_LNG from "./locales/en/translation.json";
import FR_LNG from "./locales/fr/translation.json";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

export const i18nInit = (i18n?: i18n) => {
  if (i18n) {
    i18n
      .addResourceBundle("fr", "sqin-ux", FR_LNG)
      .addResourceBundle("en", "sqin-ux", EN_LNG);
    return i18n;
  } else {
    i18next
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      .use(LanguageDetector)
      .init({
        supportedLngs: ['en', 'fr'],
        fallbackLng: "fr",
        ns: ["sqin-ux"],
        resources: {
          en: {
            "sqin-ux": EN_LNG,
          },
          fr: {
            "sqin-ux": FR_LNG,
          },
        },

        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
          order: ['querystring', 'cookie', 'localStorage'],
          lookupQuerystring: 'kc_locale',
          lookupCookie: 'KC_LOCALE',
          lookupLocalStorage: 'i18nextLng'
        }
      });
    return i18next;
  }
};
