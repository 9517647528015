import React, { FC, useState, PropsWithChildren } from "react";
import { isEmpty } from "lodash";
import { ReactComponent as Enlever } from "../../icones/enlever.svg";
import { ReactComponent as Ajouter } from "../../icones/ajouter.svg";
import { ReactComponent as Voir } from "../../icones/voir.svg";
import { ReactComponent as NePasVoir } from "../../icones/ne-pas-voir.svg";
import "./SectionPliable.css";

export type MasqueConfig = {
  labelMasqueActif: string;
  labelMasqueInactif: string;
};

export type SectionPliableProps = {
  headerRightComponent?: FC;
  titre?: string;
  sousTitre?: string;
  ouverte?: boolean;
  maskConfig?: MasqueConfig;
};

function SectionPliable(props: PropsWithChildren<SectionPliableProps>) {
  const { children, titre, sousTitre, maskConfig } = props;
  const estUnMasque = !isEmpty(maskConfig);
  const [ouverte, setOuverte] = useState<boolean>(props.ouverte || false);

  const gererClick = (evt: React.MouseEvent<HTMLInputElement>) => {
    evt.stopPropagation();
    if (estUnMasque) {
      return setOuverte(true);
    }
    setOuverte((ouverte) => !ouverte);
  };

  const trouverIcone = () => {
    if (estUnMasque) {
      return ouverte ? <Voir /> : <NePasVoir />;
    }
    return ouverte ? <Enlever /> : <Ajouter />;
  };

  return (
    <div className="wrapper">
      <div className="header" onClick={gererClick}>
        <div className="titres">
          <div className="titre">{titre}</div>
          <div className="sous-titre">{sousTitre}</div>
        </div>
        <div className="header-right">
          {estUnMasque && (
            <div className="label-masque">
              {ouverte
                ? maskConfig.labelMasqueInactif
                : maskConfig.labelMasqueActif}
            </div>
          )}
          {trouverIcone()}
        </div>
      </div>
      <div className="inside">
        {ouverte && <div className="inside-content">{children}</div>}
      </div>
    </div>
  );
}

export default SectionPliable;
