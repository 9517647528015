import Select from "react-select";
import "./ListeDeroulante.css";

type Option = {
  label: string;
  value: number | string;
};

export function trouverValeurParDefaut(options: Option[], value: any) {
  if (options && value) {
    return options.find((o) => {
      return o.value.toString() === value.toString();
    });
  }
  return value;
}

const ListeDeroulante = (props: any) => {
  const {
    options,
    disabled,
    placeholder,
    className,
    onChange,
    customStyles,
    controlId,
    ariaLabel,
    value,
    errorMessage = null,
  } = props;
  return (
    <div className={`min-width ${className}`} data-testid="my-select-component">
      <Select
        className={`${
          errorMessage
            ? "react-select__container is-invalid"
            : "react-select__container is-valid"
        }`}
        classNamePrefix="react-select"
        styles={customStyles}
        isDisabled={disabled}
        onChange={onChange}
        options={options}
        value={trouverValeurParDefaut(options, value)}
        placeholder={placeholder}
        aria-label={ariaLabel}
        aria-describedby={
          errorMessage ? `${controlId}.error.message` : undefined
        }
        aria-live="polite"
      />
      {errorMessage && <span className="erreur-message">{errorMessage}</span>}
    </div>
  );
};

export default ListeDeroulante;
