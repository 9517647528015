import { ReactComponent as Enregistrer } from "../../images/Enregistrer.svg";
import { ChampTexte, Bouton } from "../../../sqin-ux/src";
import "./style.css";

const ChangePasswordForm = () => {
  return (
    <div className={"conteneur"}>
      <h3>Modifier le mot de passe</h3>
      <ChampTexte
        name={"password-actual"}
        label={"Mot de passe actuel"}
        type={"password"}
        disabled={false}
        showpassMeter={false}
      />
      <ChampTexte
        name={"new-password"}
        label={"Nouveau mot de passe"}
        type={"password"}
        precision={["Minimum de 10 caractères"]}
        disabled={false}
        showpassMeter={false}
      />
      <ChampTexte
        name={"confirm-password"}
        label={"Confirmer le mot de passe"}
        type={"password"}
        disabled={false}
        showpassMeter={false}
      />
      <Bouton type={"secondary"}>Annuler</Bouton>
      <Bouton type={"principal"}>
        <Enregistrer className={"gauche"} /> Enregistrer les modifications
      </Bouton>
    </div>
  );
};

export default ChangePasswordForm;
