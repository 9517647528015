import * as React from "react";
import XWikiLogo from "../../logos/logoXwiki.png";
import "./styles.css";

const Accueil = () => {
  return (
    <div className={"conteneur"}>
      <h2>Librairie des composants Web normalisés du projet SQIN</h2>
      <p>
        Les composants sont des éléments réutilisables de l'interface
        utilisateur qui ont été conçus pour prendre en charge une grande variété
        d'applications.
        <br />
        <br />
        Vous pouvez utiliser les composants individuels dans de nombreux modèles
        et contextes différents. Par exemple, vous pouvez utiliser le composant
        de Champs de texte pour demander une adresse électronique ou le nom
        d'une personne.
        <br />
        <br />
        Documentation des composants sur{" "}
        <a
          target="_blank"
          className={"lien"}
          href={
            "https://sqin.cloud.xwiki.com/xwiki/bin/view/Documentations/Syst%C3%A8me%20de%20design/Composantes/"
          }
          rel="noreferrer"
        >
          XWiki <img height={16} alt={"xwiki logo"} src={XWikiLogo} />
        </a>
      </p>
    </div>
  );
};

export default Accueil;
